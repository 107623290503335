import { createContext, useContext, useState } from 'react';

const Step1Context = createContext();

export const useStep1 = () => {
  return useContext(Step1Context);
};

const Step1Provider = ({ children }) => {

  const [areaSize, setAreaSize] = useState({ Y: 6, X: 8 });
  const [areaSelected, setAreaSelected] = useState(
    Array(6).fill().map(() => Array(8).fill(1))
  );

  const handleChangeAreaY = (choice) => {
    const newY = Number(choice.value);
    setAreaSize((prev) => ({ ...prev, Y: newY }));
    setAreaSelected(Array(newY).fill().map(() => Array(areaSize.X).fill(1)));
  };

  const handleChangeAreaX = (choice) => {
    const newX = Number(choice.value);
    setAreaSize((prev) => ({ ...prev, X: newX }));
    setAreaSelected(Array(areaSize.Y).fill().map(() => Array(newX).fill(1)));
  };

  const handleChangeArea = (i, j) => {
    setAreaSelected((prev) => {
      const newArea = prev.map(row => [...row]); // 深いコピーを作成
      newArea[i][j] = 1 - newArea[i][j];
      return newArea;
    });
  };

  const [up, setUp] = useState('road');
  const [down, setDown] = useState('building');
  const [left, setLeft] = useState('building');
  const [right, setRight] = useState('building');

  return (
    <Step1Context.Provider
      value={{ 
        areaSize,
        areaSelected,
        up,
        down,
        left,
        right,
        handleChangeAreaY,
        handleChangeAreaX,
        handleChangeArea,
        setUp,
        setDown,
        setLeft,
        setRight
      }}
    >
      {children}
    </Step1Context.Provider>
  );
};

export default Step1Provider