import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select'


export function AreaYX(props) {
  const Y = props.value.Y
  const X = props.value.X

  const options = [];
  for (let i = 1; i <= 15; i++) {
    options.push({ 'value': i, 'label': i + 'm' });
  }

  return (
    <div className='select-area-yx'>
      <label>
        <Select
          className='select-area-y'
          placeholder='たて'
          options={options}
          value={{ 'value': Y, 'label': Y + 'm' }}
          defaultValue={6}
          onChange={(choice) => props.onChangeY(choice, props.room_name)}></Select>
        {'✖️'}
      </label>
      <label>
        <Select
          className='select-area-x'
          placeholder='よこ'
          options={options}
          value={{ 'value': X, 'label': X + 'm' }}
          defaultValue={8}
          onChange={(choice) => props.onChangeX(choice, props.room_name)}></Select>
      </label>
    </div>
  );

}

export function RoomYX(props) {
  const Y = props.value.Y
  const X = props.value.X

  // console.log('YX', Y, X)

  const options = [];
  for (let i = 1; i <= 12; i++) {
    options.push({ 'value': i, 'label': i });
  }

  return (
    <div className='select-room-yx'>
      <span>{props.room_name}</span>
      <br></br>
      <label>
        <Select
          className='select-y'
          placeholder='たて'
          options={options}
          // ref={YRef}
          value={{ 'value': Y, 'label': Y }}
          defaultValue={{ 'value': 6, 'label': 6 }}
          onChange={(choice) => props.onChangeY(choice, props.room_name)}></Select>
        {'✖️'}
      </label>
      <label>
        <Select
          className='select-x'
          placeholder='よこ'
          options={options}
          // ref={XRef}
          value={{ 'value': X, 'label': X }}
          defaultValue={{ 'value': 8, 'label': 8 }}
          onChange={(choice) => props.onChangeX(choice, props.room_name)}></Select>
      </label>
    </div>
  );

}