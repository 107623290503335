import { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/Contact.css';

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [validationMessage, setValidationMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // エスケープ関数
  const escapeHtml = (unsafe) => {
    return unsafe.replace(/[&<>"']/g, function(match) {
      const escapeChars = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      return escapeChars[match];
    });
  };

  const validateForm = () => {
    const { user_name, user_email, message } = formData;
    if (!user_name || !user_email || !message) {
      setValidationMessage('全ての項目を入力してください。');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(escapeHtml(user_email))) {
      setValidationMessage('正しいメールアドレスを入力してください。');
      return false;
    }
    setValidationMessage('');
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setLoading(true);
    // IPアドレスの取得
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        const ip = data.ip;
        
        // 入力データのエスケープ処理
        const escapedFormData = {};
        for (let key in formData) {
          escapedFormData[key] = escapeHtml(formData[key]);
        }

        // contactInfoオブジェクトの作成
        const contactInfo = {
          ...escapedFormData,
          user_ip: ip
        };

        // メールの送信
        emailjs.send('service_ycpyfc7', 'template_0o1o70p', contactInfo, 'apug09CZSYGjbZt6m')
          .then((result) => {
            console.log(result.text);
            setResponseMessage('お問い合わせを送信しました');
            setTimeout(() => setResponseMessage(''), 5000); // 5秒後にメッセージを消す
            setFormData({ user_name: '', user_email: '', message: '' }); // フォームのリセット
            setLoading(false);
          }, (error) => {
            console.log(error.text);
            setResponseMessage('送信に失敗しました、お手数ですが再度お試しください');
            setTimeout(() => setResponseMessage(''), 5000); // 5秒後にメッセージを消す
            setLoading(false);
          });
      });
  };

  return (
    <div className={validationMessage || responseMessage ? "shrinked-container-contact" : "container-contact"}>
      <h3 className="title-contact">お問い合せ</h3>
      <p className="description-contact">
        間取りの窓口（ベータ版）をご利用いただきありがとうございます。<br/>
        ご質問やご要望がございましたら、お気軽にお問い合わせください。<br/>
        ご利用いただいた感想や改善点なども大歓迎です！
      </p>
      <form ref={form} className="form-contact" onSubmit={sendEmail}>
        <label>お名前</label>
        <input type="text" className="user-name-contact" name="user_name" value={formData.user_name} onChange={handleChange} />
        
        <label>メールアドレス</label>
        <input type="text" className="user-email-contact" name="user_email" value={formData.user_email} onChange={handleChange} />
        
        <label>お問い合せ内容</label>
        <textarea name="message" className="message-contact" value={formData.message} onChange={handleChange} />
        
        <button type="submit" className={!loading ? "submit-contact" : "disabled-submit-contact"} value="送信" disabled={loading}>送信</button>
      </form>
      {validationMessage && <p className="validation-message-contact">{validationMessage}</p>}
      {responseMessage && <p className="response-message-contact">{responseMessage}</p>}
    </div>
  );
};

export default Contact;