import "../css/NotFound.css";

const NotFound = () => {
  return (
    <div className="container-not-found">
      <h1 className="title-not-found">404 - Not Found!</h1>
    </div>
  );
}

export default NotFound;