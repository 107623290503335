import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WarningBanner from './WarningBanner.jsx';
import { useStep1 } from '../contexts/Step1Context.jsx';
import { useStep2 } from '../contexts/Step2Context.jsx';
import { useStep3 } from '../contexts/Step3Context.jsx';
import { useResult } from '../contexts/ResultContext.jsx';

const AutoGenerateButton = () => {
  const {
    areaSize,
    areaSelected,
    up,
    down,
    left,
    right,
  } = useStep1();

  const {
    nFloor,
    nParking,
    nLDK,
  } = useStep2();

  const {
    requiredRooms,
    extractNumber
  } = useStep3();

  const {
    results,
    status,
    loading,
    setResults,
    setLoading,
    setStatus
  } = useResult();

  const [dots, setDots] = useState(0);
  const [completionMessage, setCompletionMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setInterval(() => {
        setDots(prevDots => (prevDots + 1) % 4);
      }, 500);
    } else if (!loading) {
      if (results.length > 0) setCompletionMessage(true);
      setTimeout(() => {
        setCompletionMessage(false);
        setDots(0);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [loading]);

  const parseRequiredRooms = (requiredRooms) => {
    const formattedRequiredRooms = requiredRooms
      .filter((requiredRoom) => (
        requiredRoom.requiredRoomTypeDropdown !== '部屋を選択する'
      ))
      .reduce((acc, requiredRoom) => {
        const roomType = requiredRoom.requiredRoomTypeDropdown;
        const width = extractNumber(requiredRoom.requiredRoomWidthDropdown);
        const height = extractNumber(requiredRoom.requiredRoomHeightDropdown);
        const roomSize = { width, height };

        if (acc[roomType]) {
          acc[roomType].push(roomSize);
        } else {
          acc[roomType] = [roomSize];
        }

        return acc;
      }, {});

    return formattedRequiredRooms;
  }

  const fetchSendData = () => {
    return {
      area_size: areaSize,
      area_selected: areaSelected,
      up,
      down,
      left,
      right,
      n_floor: Number(nFloor),
      n_parking: Number(nParking),
      n_LDK: Number(nLDK),
      required_rooms: parseRequiredRooms(requiredRooms)
    }
  }

  const runSolver = (e) => {
    e.preventDefault()

    // Google Analytics のイベント送信
    window.gtag('event', 'auto_generate', {
      event_category: 'auto_generate',
      event_label: '間取り自動生成ボタン',
      value: 1
    });

    setLoading(true)
    setResults([])

    const sessionId = Math.random().toString(36);
    const urlPost = `${process.env.REACT_APP_ALGORITHM_URL}/auto-generate`;
    const data = JSON.stringify(fetchSendData());
    const headers = {
      'Content-Type': 'application/json',
      'Session-Id': sessionId // session_idを渡すことで、ユーザを識別。クエリの混同を防ぐ。
    };
    console.log('POST data:', data);

    axios
      .post(urlPost, data, { headers: headers })
      .then(response => {
        console.log('data:', response.data);
        // Set up SSE with Session-ID as query parameter
        const urlPostWithSessionId = `${urlPost}?session_id=${sessionId}`; // session_idを渡すことで、ユーザを識別。クエリの混同を防ぐ。
        const eventSource = new EventSource(urlPostWithSessionId);

        eventSource.onmessage = (event) => {
          const eventData = JSON.parse(event.data);
          console.log('data:', eventData);
          setResults(eventData.results)
          setLoading(true)
          setStatus('')
        };

        eventSource.onerror = (err) => {
          console.error('EventSource StopIteration:', err);
          setLoading(false)
          setStatus('')
          eventSource.close();
        };
      })
      .catch(error => {
        if (error.response) {
          console.log('Server Error:', error.response.data);
          console.log('Status code:', error.response.status);
          setStatus('server error')
        } else if (error.request) {
          console.log('No response received:', error.request);
          setStatus('no response')
        } else {
          console.log('Error:', error.message);
          setStatus('Error')
        }
        console.log('Error config:', error.config);
      });

    console.log('running solver -> results:', results);
  };

  return (
    <>
      <button
        className={!loading ? 'solve-button' : 'disabled-solve-button'}
        onClick={!loading ? runSolver : undefined}
        disabled={loading}>
        <img className="auto-create-arrow" src={!loading ? "/images/auto-create-arrow.png" : "/images/disable-auto-create-arrow.png"} alt="arrow in button" />
        <span className={!loading ? 'solve-button-text' : 'disabled-solve-button-text'}>
          {!loading ? (completionMessage ? '生成完了！' : '間取りを自動で作成する') : `生成中${'.'.repeat(dots)}`}
        </span>
      </button>
      <WarningBanner status={status} />
    </>
  );
};

export default AutoGenerateButton;