// こだわり条件：部屋の種類
export const requiredRoomTypeDropdownOptions = [
  '部屋を選択する',
  '駐輪場',
  'ポーチ',
  'ウッドデッキ',
  '光庭',
  '中庭',
  'セカンドリビング',
  '和室',
  'ワークスペース',
  'シアタールーム',
  'ランドリールーム',
  // 'パントリー',
  'WIC',
  'シューズクローク',
  '吹抜け',
  'バルコニー'
];

// こだわり条件：部屋の横デフォルト
export const requiredRoomWidthOptions = {
  '部屋を選択する': ['数値を入力する'],
  '駐輪場': ['1(標準)', '2(やや広い)', '3(広い)', '4(とても広い)', '5(とても広い)'],
  'ポーチ': ['1(標準)', '2(やや広い)', '3(広い)', '4(とても広い)', '5(とても広い)'],
  'ウッドデッキ': ['1(標準)', '2(やや広い)', '3(広い)', '4(とても広い)', '5(とても広い)'],
  '光庭': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  '中庭': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'セカンドリビング': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  '和室': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'ワークスペース': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'シアタールーム': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'ランドリールーム': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  // 'パントリー': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'WIC': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'シューズクローク': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  '吹抜け': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'バルコニー': ['1(標準)', '2(やや広い)', '3(広い)', '4(とても広い)', '5(とても広い)']
};

// こだわり条件：部屋の縦デフォルト
export const requiredRoomHeightOptions = {
  '部屋を選択する': ['数値を入力する'],
  '駐輪場': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'ポーチ': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'ウッドデッキ': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  '光庭': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  '中庭': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'セカンドリビング': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  '和室': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'ワークスペース': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'シアタールーム': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'ランドリールーム': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  // 'パントリー': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  'WIC': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'シューズクローク': ['1(やや狭い)', '2(標準)', '3(やや広い)', '4(広い)', '5(とても広い)'],
  '吹抜け': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)'],
  'バルコニー': ['1(狭い)', '2(やや狭い)', '3(標準)', '4(やや広い)', '5(広い)']
};


export const requiredRoomDefaultWidthtList = {
  '駐輪場': 0,
  'ポーチ': 0,
  'ウッドデッキ': 0,
  '光庭': 1,
  '中庭': 1,
  'セカンドリビング': 2,
  '和室': 1,
  'ワークスペース': 1,
  'シアタールーム': 1,
  'ランドリールーム': 1,
  // 'パントリー': 1,
  'WIC': 1,
  'シューズクローク': 1,
  '吹抜け': 2,
  'バルコニー': 0
};

export const requiredRoomDefaultHeightList = {
  '駐輪場': 1,
  'ポーチ': 1,
  'ウッドデッキ': 2,
  '光庭': 1,
  '中庭': 2,
  'セカンドリビング': 2,
  '和室': 2,
  'ワークスペース': 1,
  'シアタールーム': 2,
  'ランドリールーム': 1,
  // 'パントリー': 1,
  'WIC': 2,
  'シューズクローク': 1,
  '吹抜け': 2,
  'バルコニー': 2
};