import { createContext, useContext, useEffect, useState } from 'react';

const ResultContext = createContext();

export const useResult = () => {
  return useContext(ResultContext);
};

const ResultProvider = ({ children }) => {

  const [results, setResults] = useState([])
  const [resultList, setResultList] = useState([])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')

  useEffect(() => {
    // 重複を排除して最新のデータのみ取得する処理
    const resultMap = {}
    results.forEach(result => {
      resultMap[result.title] = result
    });

    // オブジェクトから配列に戻す
    const newResultList = Object.values(resultMap)
    setResultList(newResultList)
  }, [results])

  return (
    <ResultContext.Provider
      value={{ 
        results,
        resultList,
        loading,
        status,
        setResults,
        setResultList,
        setLoading,
        setStatus
      }}
    >
      {children}
    </ResultContext.Provider>
  );
};

export default ResultProvider