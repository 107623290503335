import "../css/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="container-privacy-policy">
      <h1 className="title-privacy-policy">プライバシーポリシー</h1>
      <div className="section-wrapper-privacy-policy">
        <h2 className="section-title-privacy-policy">個人情報の利用目的</h2>
        <p className="content-text-privacy-policy">
            当サイトでは、お問い合わせや記事へのコメントの際、名前やメールアドレスなどの個人情報を入力いただく場合がございます。
            取得した個人情報は、お問い合わせに対する回答や必要な情報をメールなどでご連絡する場合に利用させていただくものであり、
            これらの目的以外では利用いたしません。
        </p>
      </div>
      <div className="section-wrapper-privacy-policy">
        <h2 className="section-title-privacy-policy">アクセス解析ツールについて</h2>
        <p className="content-text-privacy-policy">
            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。
            このデータは匿名で収集されており、個人を特定するものではありません。
        </p>
        <p className="content-text-privacy-policy">
            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
            この規約に関しての詳細は
            <a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" className="external-link-privacy-policy">Googleアナリティクスサービス利用規約</a>のページや
            <a href="https://policies.google.com/technologies/ads?hl=ja" target="_blank" className="external-link-privacy-policy">Googleポリシーと規約</a>ページをご覧ください。
        </p>
      </div>
      <div className="section-wrapper-privacy-policy">
        <h2 className="section-title-privacy-policy">お問い合わせフォームについて</h2>
        <p className="content-text-privacy-policy">
            当サイトはコメントやお問い合わせを記録する際に、IP アドレスを収集しています。これはサイトの標準機能としてサポートされている機能で、
            スパムや荒らしへの対応以外にこのIPアドレスを使用することはありません。また、全てのコメントは管理人が事前にその内容を確認し、
            承認した上での掲載となります。あらかじめご了承ください。
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;