// 間取り出力中に表示するTips
// 後からDBに移行したい
export const tipsWhileLoading = [
  '投資の神様ウォーレン・バフェット氏の言葉\n「これまでの投資の中で最高の投資は二人の妻に送った『指輪』だ。次に良かった投資は『家』だ」',
  '最近は基礎や躯体、外壁などだけは業者に作ってもらい、\nその他の部分を自分でDIYで作っていくというハーフビルドの家というものも流行り！？',
  'オシャレな家を作るためには\n「余白を作ること」「テイストを揃えること」「遊びを作ること」が大事です',
  '近代建築の巨匠 ミース・ファン・デル・ローエの言葉\n『Less is more(少ない方が豊かである)』',
  '私が思うに間取りで後悔しないための心構えは\n「施主が積極的に間取りに意見すること」ではないかと思います',
  '家の面積を大きく取れないときには『造作家具』を作ることで、面積を有効活用できることもあります！',
  '「家具・家電をインターネットに繋ぐことで豊かな生活を目指した家」であるスマートホームは、\n家事を楽にする方法の一つです',
  'インテリアのテイストには『モダン』『北欧』『工業系』など様々な種類がありますが、\nあなたは何派ですか？',
  'あまり知られていませんが、『LDK』型の間取りが日本で普及したのはなんと戦後のことで、\nそれまでは田の字型プランが主流でした',
  '親世帯と子世帯の住居の理想の距離はよく『スープの冷めない距離』と言われます。\n作った料理をもう片方の家に届けるのに料理が冷めないくらいの距離ということらしいです。',
  '建築家 篠原一男のシンプルでカッコいい言葉\n『住宅は芸術である』',
  '日本の住宅の構造形式は圧倒的に木造が多く、\n90%超は『木造』、8%は『鉄骨造』、そして0.2%は『その他』です',
  '近代建築の巨匠 ル・コルビュジェの言葉\n『住宅は住むための機械である』'
];