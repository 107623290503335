import {useRef} from 'react';
import RequiredRoomPreview from './RequiredRoomPreview';
import '../css/RoomTypeDropdown.css';
import {requiredRoomTypeDropdownOptions, requiredRoomWidthOptions, requiredRoomHeightOptions} from '../constants/const.jsx';

const RoomTypeDropdown = ({ 
  requiredRoomTypeDropdown, 
  setRequiredRoomTypeDropdown, 
  requiredRoomWidthDropdown, 
  setRequiredRoomWidthDropdown, 
  requiredRoomHeightDropdown, 
  setRequiredRoomHeightDropdown }) => {

  const selectTypeRef = useRef(null);
  const selectWidthRef = useRef(null);
  const selectHeightRef = useRef(null);

  const selectTypeChange = (e) => {
    setRequiredRoomTypeDropdown(e.target.value);
    selectTypeRef.current.blur();
  };

  const selectWidthChange = (e) => {
    setRequiredRoomWidthDropdown(e.target.value);
    selectWidthRef.current.blur();
  };

  const selectHeightChange = (e) => {
    setRequiredRoomHeightDropdown(e.target.value);
    selectHeightRef.current.blur();
  };

  return (
    <div className='container-room-type-dropdown'>
      <div className='upper-row-room-type-dropdown'>
        <div className='select-type-wrapper-room-type-dropdown'>
          <select className='select-type-room-type-dropdown' value={requiredRoomTypeDropdown} ref={selectTypeRef} onChange={e => selectTypeChange(e)}>
            {requiredRoomTypeDropdownOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <RequiredRoomPreview
          requiredRoomWidthDropdown={requiredRoomWidthDropdown}
          requiredRoomHeightDropdown={requiredRoomHeightDropdown}
          visible={requiredRoomTypeDropdown !== '部屋を選択する'}
        />
      </div>

      <div className='lower-row-room-type-dropdown'>
        <div className='select-size-label-room-type-dropdown'>大きさ</div>
        <div className='green-border-room-type-dropdown'></div>
          {requiredRoomTypeDropdown === '部屋を選択する' ? (
          <div className='inactive-select-size-wrapper-room-type-dropdown'>
            <select className='inactive-select-size-room-type-dropdown' disabled>
              <option>指定しない</option>
            </select>
          </div>
          ) : (
          <div className='select-size-wrapper-room-type-dropdown'>
            <select className='select-size-room-type-dropdown' value={requiredRoomWidthDropdown} ref={selectWidthRef} onChange={e => selectWidthChange(e)}>
              {requiredRoomWidthOptions[requiredRoomTypeDropdown].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
              ))}
            </select>
          </div>
          )}
        <div className='multiply-sign-room-type-dropdown'></div>
          {requiredRoomTypeDropdown === '部屋を選択する' ? (
          <div className='inactive-select-size-wrapper-room-type-dropdown'>
            <select className='inactive-select-size-room-type-dropdown' disabled>
              <option>指定しない</option>
            </select>
          </div>
          ) : (
          <div className='select-size-wrapper-room-type-dropdown'>
            <select className='select-size-room-type-dropdown' value={requiredRoomHeightDropdown} ref={selectHeightRef} onChange={e => selectHeightChange(e)}>
              {requiredRoomHeightOptions[requiredRoomTypeDropdown].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
              ))}
            </select>
          </div>
          )}
      </div>
    </div>
  );
};

export default RoomTypeDropdown;
