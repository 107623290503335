import '../css/RequiredRoomPreview.css';
import { useStep3 } from '../contexts/Step3Context.jsx'

const RequiredRoomPreview = ({ requiredRoomWidthDropdown, requiredRoomHeightDropdown, visible }) => {

  const { extractNumber } = useStep3();

  const width = extractNumber(requiredRoomWidthDropdown);
  const height = extractNumber(requiredRoomHeightDropdown);
  const backWidth = 5;
  const backHeight = 5;

  return (
    <div className='rectangle-wrapper-required-room-preview'>
      <div className='rectangle-back-required-room-preview' style={{ width: `${backWidth * 20}px`, height: `${backHeight * 20}px` }}>
        { visible && 
          <div className='rectangle-front-required-room-preview' style={{ width: `${width * 20}px`, height: `${height * 20}px` }}>
            <span className='top-required-room-preview'>{`${width * 910}`}</span>
            <span className='left-required-room-preview'>{`${height * 910}`}</span>
          </div>
        }
      </div>
    </div>
  );
};

export default RequiredRoomPreview;
