const WarningBanner = ({ status }) => {

  if (status === '') {
    return null;
  }

  return (
    <div className='warning'>
      Error: {status}
    </div>
  );
}

export default WarningBanner