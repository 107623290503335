import { useNavigate } from "react-router-dom";
import routes from '../constants/routes.js';
import '../css/Header.css';
import HeaderButton from './HeaderButton.jsx';

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(routes.top);  // クリックしたときに遷移するパスを指定
  };

  const handleLogoKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(routes.top);  // Enter または Space キーが押されたときに遷移するパスを指定
    }
  };

  return (
    // <header> プレリリース時は本番用をコメントアウト
    //   <div className="container-header">
    //     <img
    //       className="header-logo-header"
    //       src="/images/logo.png"
    //       alt="logo at header"
    //       onClick={handleLogoClick}
    //       onKeyDown={handleLogoKeyDown}
    //       tabIndex="0"
    //     />
    //     <HeaderButton text="間取りの自動生成" img="auto-genarate" to={routes.top} />
    //     <HeaderButton text="お問い合わせ" img="estimate" to={routes.contact} />
    //     <HeaderButton text="広告掲載について" img="ad" to={routes.top} />
    //   </div>
    // </header>
    <header>
      <div className="container-header">
        <div className="logo-container-header">
          <img
            className="header-logo-header"
            src="/images/logo.png"
            alt="logo at header"
            onClick={handleLogoClick}
            onKeyDown={handleLogoKeyDown}
            tabIndex="0"
          />
          <img className="beta-header" src="/images/beta-at-logo.png" alt="beta at logo" />
        </div>
        <HeaderButton text="間取りの自動生成" img="auto-genarate" to={routes.top} />
        <HeaderButton text="お問い合わせ" img="estimate" to={routes.contact} />
        <HeaderButton text="広告掲載について" img="ad" to={routes.top} />
      </div>
    </header>
  );
}

export default Header;