import { memo } from 'react'
import '../css/SelectionTitleLabel.css'

const SelectionTitleLabel = ({ title }) => {

  return (
    <div className='container-selection-title-label'>
      <div className='content-selection-title-label'>
        <span>{title}</span>
      </div>
    </div>
  )
}

export default memo(SelectionTitleLabel)