import "../css/Message.css"

const Message = () => {
  return (
    <div className="container-message">
      <div className="contents-message">
        <div className="image-container-message">
          <img className="image-message" src="/images/noguchiyuto.png" alt="representative face" />
        </div>
        <div className="message-container-message">
          <p className="message">
            こんにちは！<br/>
            『間取りの窓口』代表の野口雄人です</p>
          <p className="message">
            私たちは「<span className="underline-message">家づくりをもっと簡単に</span>楽しく」<br/>
            モットーに本サービスを提供しております
          </p>
          <p className="message">
            是非このサービスで、たくさんの間取りを作って<br/>
            家づくりを楽しんでください！
          </p>
        </div>
      </div>
    </div>
  );
}

export default Message;