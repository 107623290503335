import { useNavigate } from "react-router-dom";
import '../css/HeaderButton.css';

const HeaderButton = ({ text, img, to }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);  // クリックしたときに遷移するパスを指定
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(to);  // Enter または Space キーが押されたときに遷移するパスを指定
    }
  };

  return (
    <button
      className="header-button" 
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <img src={`/images/header-${img}.png`} alt="header-button-illustration" />
      {text}
    </button>
  );
}

export default HeaderButton;