import { Audio } from 'react-loader-spinner'
import Tips from './Tips.jsx'
import { useResult } from '../contexts/ResultContext.jsx'
import { useState } from 'react'
import '../css/Result.css'

const Result = () => {

  const {
    loading,
    resultList
  } = useResult()

  const [showAllImages, setShowAllImages] = useState({})

  // 間取りの画像を全て表示するボタンをクリックした時の処理
  // 全件表示の時は一瞬、一件表示に戻すときはゆっくりスクロールで一件目までスクロールする
  const handleShowAllClick = (index, action) => {
    if (!action) {
      const element = document.getElementById(`id-${index}`);
      element.scrollIntoView({  
        behavior: 'smooth'  
      });
    }
    setTimeout(() => {
      setShowAllImages(prev => ({ ...prev, [index]: action }))
    }, action ? 0 : 500 )
  }

  return (
    <>
      <Tips />
      {resultList.map((result, index) => (
        <div id={`id-${index}`} key={index} className='result-container-result'>
          <img className="house-mark-result" src={`/images/house-mark-${index+1}.png`} alt="arrow" />
          <div className="floor-plan-description-wrapper-result">
            <div className="floor-plan-title-container-result">
              <img className="for-icon-result" src="/images/for-icon.png" alt="for icon" />
              <p className='floor-plan-title-result'>{result.title}</p>
            </div>
            <div className="floor-plan-explanation-container-result">
            <img className="point-icon-result" src="/images/point-icon.png" alt="point icon" />
              <p className='floor-plan-explanation-result'>{result.explanation}</p>
            </div>
          </div>
          <div className='floor-plan-images-result'>
            {result.imgs
              .slice(0, showAllImages[index] ? result.imgs.length : 1)
              .map((img, imgIndex) => (
                <img key={imgIndex} src={`data:image/svg+xml;base64,${img}`} alt='result' />
              ))}
          </div>
          {result.imgs.length > 1 && (
            <button
              className='show-all-button-result'
              onClick={() => handleShowAllClick(index, !showAllImages[index])}>
              <img 
                className={showAllImages[index] ? "show-all-close-arrow-result" : "show-all-open-arrow-result"} 
                src="/images/auto-create-arrow.png" 
                alt="arrow" 
              />
              <span className='show-all-button-text-result'>
                {showAllImages[index] ? '一件だけ表示する' : '似た間取りを全て表示する'}
              </span>
            </button>
          )}
        </div>
      ))}

      <Audio
        height='80'
        width='80'
        radius='9'
        color='green'
        ariaLabel='loading'
        visible={loading}
      />
    </>
  )
}

export default Result
