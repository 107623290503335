import '../css/ParkingSelection.css'
import SelectionTitleLabel from './SelectionTitleLabel'
import { useStep2 } from '../contexts/Step2Context.jsx'

const ParkingSelection = () => {

  const { nParking, setNParking } = useStep2()

  const PARKING_SELECT_OPTIONS = [0, 1, 2, 3]

  const handleKeyDown = (e, value) => {
    if ((e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault()
      setNParking(value)
    }
  }

  return (
    <div className='container-parking-selection'>
      <SelectionTitleLabel title='駐車台数' />
      <div className='button-container-parking-selection'>
        {PARKING_SELECT_OPTIONS.map((parking) => (
          <div key={parking}>
            <input 
              type='radio'
              name='parking-select' 
              className={Number(nParking)===parking ? 'checked-parking-selection' : undefined}
              value={parking} 
              id={`parking-select-${parking}`}
              checked={Number(nParking)===parking}
              onChange={parking<2 ? (e) => setNParking(e.target.value) : undefined}
              disabled={parking>1}
            />
            <label 
              htmlFor={`parking-select-${parking}`}
              className={parking<2 ? 'input-label-parking-selection' : 'disable-input-label-parking-selection'}
              onClick={parking<2 ? (e) => setNParking(e.target.value) : undefined}
              data-value={parking}
              disabled={parking>1}
              tabindex={parking<2 ? "0" : "-1"}
              onKeyDown={(e) => handleKeyDown(e, parking)}
            >
              <div 
                className='button-text-parking-selection' 
                data-value={parking}
              >
                {parking}台
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}


export default ParkingSelection