import { useEffect, useState } from 'react';
import { useResult } from '../contexts/ResultContext.jsx'
import { tipsWhileLoading } from '../constants/tipsList.jsx';
import '../css/Tips.css'

const Tips = () => {
  const { loading } = useResult();
  const [isDisplayed, setIsDisplayed] = useState(false); // コンポーネントの表示状態
  const [currentTip, setCurrentTip] = useState(tipsWhileLoading[0]);
  const [ , setPreviousTip] = useState(null);

  useEffect(() => {
    // 初めてloadingがtrueになったときに表示を設定する
    if (loading) {
      setIsDisplayed(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) return;

    const interval = setInterval(() => {
      let nextTip;
      do {
        nextTip = tipsWhileLoading[Math.floor(Math.random() * tipsWhileLoading.length)];
      } while (nextTip === currentTip);
      setPreviousTip(currentTip);
      setCurrentTip(nextTip);
    }, 10000); // 10秒ごと

    return () => clearInterval(interval);
  }, [loading, currentTip]);

  return (
    isDisplayed && (
      <div className='tips-container-tips'>
        <div className='tips-wrapper-tips'>
          <img src="/images/tips.png" alt="tips icon" />
          <p className='tips-text-tips'>
            {currentTip}
          </p>
        </div>
      </div>
    )
  );
};

export default Tips;