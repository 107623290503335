import { createContext, useContext, useState, useCallback } from 'react';
import { requiredRoomWidthOptions, requiredRoomHeightOptions, requiredRoomDefaultWidthtList, requiredRoomDefaultHeightList } from '../constants/const.jsx';

const Step3Context = createContext();

export const useStep3 = () => {
  return useContext(Step3Context);
};

export const Step3Provider = ({ children }) => {
  const [requiredRooms, setRequiredRooms] = useState([]);

  const addRequiredRoom = useCallback(() => {
    setRequiredRooms((prev) => [
      ...prev,
      {
        id: prev.length,
        requiredRoomTypeDropdown: '部屋を選択する',
        requiredRoomWidthDropdown: '数値を入力する',
        requiredRoomHeightDropdown: '数値を入力する',
      },
    ]);
  }, []);

  const removeRequiredRoom = useCallback((id) => {
    setRequiredRooms((prev) => prev.filter((room) => room.id !== id));
  }, []);

  const updateRequiredRoom = useCallback((id, updatedState) => {
    setRequiredRooms((prev) =>
      prev.map((room) => (room.id === id ? { ...room, ...updatedState } : room))
    );
  }, []);

  const handleChangeRequiredRoomType = useCallback((id, value) => {
    let updatedState = {
      requiredRoomTypeDropdown: value,
      requiredRoomWidthDropdown: '数値を入力する',
      requiredRoomHeightDropdown: '数値を入力する',
    };

    if (value !== '部屋を選択する') {
      updatedState.requiredRoomWidthDropdown = requiredRoomWidthOptions[value][requiredRoomDefaultWidthtList[value]];
      updatedState.requiredRoomHeightDropdown = requiredRoomHeightOptions[value][requiredRoomDefaultHeightList[value]];
    }
    updateRequiredRoom(id, updatedState);
  }, [updateRequiredRoom]);

  const extractNumber = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  return (
    <Step3Context.Provider
      value={{
        requiredRooms,
        addRequiredRoom,
        removeRequiredRoom,
        updateRequiredRoom,
        handleChangeRequiredRoomType,
        extractNumber,
      }}
    >
      {children}
    </Step3Context.Provider>
  );
};

export default Step3Provider
