import '../css/StepHeader.css'

const StepHeader = ({stepNumber, description}) => {
  return (
    <>
      <div className='background-sapce-step-header'></div>
      <div className='band-step-header'>
        <div className='icon-step-header'>
          <div className='icon-roof-step-header'></div>
          <div className='icon-body-step-header'></div>
          <div className='step-number-step-header'>
            {stepNumber}
          </div>
        </div>
        <div className='description-step-header'>{description}</div>
      </div>
    </>
  )
}

export default StepHeader