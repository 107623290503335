import { createContext, useContext, useState } from 'react';

const Step2Context = createContext();

export const useStep2 = () => {
  return useContext(Step2Context);
};

const Step2Provider = ({ children }) => {

  const [nFloor, setNFloor] = useState(1);
  const [nParking, setNParking] = useState(0);
  const [nLDK, setNLDK] = useState(1);
  // const [results, setResults] = useState({});
  // const [resultsImg, setResultsImg] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [status, setStatus] = useState('');

  return (
    <Step2Context.Provider
      value={{
        nFloor,
        nParking,
        nLDK,
        setNFloor,
        setNParking,
        setNLDK
      }}
    >
      {children}
    </Step2Context.Provider>
  );
};

export default Step2Provider