import SelectionTitleLabel from './SelectionTitleLabel'
import RoomTypeDropdown from './RoomTypeDropdown';
import '../css/RequiredRoomSelection.css';
import { useStep3 } from '../contexts/Step3Context.jsx'

const RequiredRoomSelection = () => {
  const { 
    requiredRooms, 
    addRequiredRoom, 
    updateRequiredRoom, 
    removeRequiredRoom, 
    handleChangeRequiredRoomType
  } = useStep3();

  const handleKeyDownAdd = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      addRequiredRoom();
    }
  }

  const handleKeyDownDelete = (e, id) => {
    if (e.key === 'Enter' || e.key === ' ') {
      removeRequiredRoom(id);
    }
  }

  return (
    <div className='container-required-room-selection'>
      <SelectionTitleLabel title='部屋'/>
      <div className='added-rooms-required-room-selection'>
        {requiredRooms.map((requiredRoom) => (
          <div key={requiredRoom.id} className='added-room-required-room-selection'>
            <button 
              className='delete-button-required-room-selection'
              onClick={() => removeRequiredRoom(requiredRoom.id)}
              tabIndex='0'
              onKeyDown={(e) => {handleKeyDownDelete(e, requiredRoom.id)}}
            ></button>
            <RoomTypeDropdown
              requiredRoomTypeDropdown={requiredRoom.requiredRoomTypeDropdown}
              setRequiredRoomTypeDropdown={(value) => handleChangeRequiredRoomType(requiredRoom.id, value)}
              requiredRoomWidthDropdown={requiredRoom.requiredRoomWidthDropdown}
              setRequiredRoomWidthDropdown={(value) => updateRequiredRoom(requiredRoom.id, { requiredRoomWidthDropdown: value })}
              requiredRoomHeightDropdown={requiredRoom.requiredRoomHeightDropdown}
              setRequiredRoomHeightDropdown={(value) => updateRequiredRoom(requiredRoom.id, { requiredRoomHeightDropdown: value })}
            />
          </div>
        ))}
        <div 
          className='buttonContainer'
          onClick={addRequiredRoom}
          tabIndex='0'
          onKeyDown={(e) => {handleKeyDownAdd(e)}}
        >
          <span className='plus-icon-required-room-selection'></span>
          欲しい部屋を追加する
        </div>
      </div>
    </div>
  );
};

export default RequiredRoomSelection;
