import React from 'react'
import Select from 'react-select'

export function NeighborSelect({name, pos, value, handleChange }) {

  const resultDict = {
    'road': '道路',
    'building': '建物',
    'other': 'その他'
  }

  const options = [
    { 'value': 'road', 'label': '道路' },
    { 'value': 'building', 'label': '建物' },
    { 'value': 'other', 'label': 'その他' }
  ]

  const classNames = {
    'up': 'neigber-selector top-neigber-selector',
    'down': 'neigber-selector down-neigber-selector',
    'left': 'neigber-selector left-neigber-selector',
    'right': 'neigber-selector right-neigber-selector'
  }

  return (
    <div className='neigber-selector-container'>
      <div className='neigber-selector-label-container'>
        <label className='neigber-selector-label'>{name}</label>
      </div>
      <Select
        className={classNames[pos]}
        options={options}
        defaultValue={pos === 'up' ? options[0] : options[1]}
        onChange={(selectedOption) => handleChange(selectedOption.value)}
      />
    </div>
  );
}