import {useState} from 'react'
import '../css/FloorSelection.css'
import SelectionTitleLabel from './SelectionTitleLabel'
// import { useRequirements } from '../contexts/RequirementsContexts.jsx'

const FloorSelection = () => {
  // グレーアウト版
  // const { handleChangeFloor } = useRequirements()

  const [floorSelection, setFloorSelection] = useState(1)
  // const checkFloorSelection = (e) => {
  //   setFloorSelection(e.target.dataset.value)
  //   handleChangeFloor(e.target.dataset.value)
  // }

  const FLOOR_SELECT_OPTIONS = [1, 2, 3]

  const handleKeyDown = (e, value) => {
    if ((e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault()
      setFloorSelection(value)
    }
  }

  return (
    <div className='container-floor-selection'>
      <SelectionTitleLabel title='階数' />
      <div className='button-container-floor-selection'>
      { FLOOR_SELECT_OPTIONS.map((floor) => (
        <div key={floor}>
          <input 
            type='radio'
            name='floor-select' 
            className={Number(floorSelection)===floor ? 'checked-floor-selection' : undefined}
            value={floor} 
            id={`floor-select-${floor}`}
            disabled={floor!==1}
            checked={Number(floorSelection)===floor}
          />
          <label 
            htmlFor={`floor-select-${floor}`}
            className={floor===1 ? 'input-label-floor-selection' : 'disable-input-label-floor-selection'}
            data-value={floor}
            disabled={floor!==1}
            tabIndex={floor===1 ? "0" : "-1"}
            onKeyDown={(e) => handleKeyDown(e, floor)}
          >
            <img 
              className='button-image-floor-selection'
              src={floor ===1 ? `/images/${floor}-storey.png` : `/images/disable-${floor}-storey.png`} 
              alt={`${floor}storey house img`}
              data-value={floor}
            />
            <div 
              className='button-text-floor-selection' 
              data-value={floor}
            >
              {floor}階建て
            </div>
          </label>
        </div>
      )) }
      </div>
    </div>
  )
  
  // const { handleChangeFloor } = useRequirements()

  // const [floorSelection, setFloorSelection] = useState(1)
  // const checkFloorSelection = (e) => {
  //   setFloorSelection(e.target.dataset.value)
  //   handleChangeFloor(e.target.dataset.value)
  // }

  // const FLOOR_SELECT_OPTIONS = [1, 2, 3]

  // return (
  //   <div className='container-floor-selection'>
  //     <SelectionTitleLabel title='階数' />
  //     <div className='button-container-floor-selection'>
  //     { FLOOR_SELECT_OPTIONS.map((floor) => (
  //       <div key={floor}>
  //         <input 
  //           type='radio'
  //           name='floor-select' 
  //           className={Number(floorSelection)===floor ? 'checked-floor-selection' : undefined}
  //           value={floor} 
  //           id={`floor-select-${floor}`}
  //           checked={Number(floorSelection)===floor}
  //           onChange={checkFloorSelection}
  //         />
  //         <label 
  //           htmlFor={`floor-select-${floor}`}
  //           className='input-label-floor-selection'
  //           onClick={checkFloorSelection}
  //           data-value={floor}
  //         >
  //           <img 
  //             className='button-image-floor-selection' 
  //             src={`/images/${floor}-storey.png`} 
  //             alt={`${floor}storey house img`}
  //             data-value={floor}
  //           />
  //           <div 
  //             className='button-text-floor-selection' 
  //             data-value={floor}
  //           >
  //             {floor}階建て
  //           </div>
  //         </label>
  //       </div>
  //     )) }
  //     </div>
  //   </div>
  // )
}

export default FloorSelection