import { AreaYX } from './room_size_pulldown.js'
import { NeighborSelect } from './neighbor_select.js'
import AreaGrid from './area_selection.js'
import { useStep1 } from '../contexts/Step1Context.jsx'

const AreaInput = () => {

  const {
    areaSize, 
    areaSelected, 
    up,
    down,
    left,
    right,
    handleChangeAreaY, 
    handleChangeAreaX, 
    handleChangeArea,
    setUp,
    setDown,
    setLeft,
    setRight
  } = useStep1();

  return (
    <>
      <AreaYX
        key='全体'
        value={{ 'Y': areaSize.Y, 'X': areaSize.X }}
        onChangeY={(choice) => handleChangeAreaY(choice)}
        onChangeX={(choice) => handleChangeAreaX(choice)}
      />

      <div className='top'>
        <NeighborSelect name='北' pos='up' value={up} handleChange={setUp} />
      </div>
      <div className='middle'>
        <NeighborSelect name='西' pos='left' value={left} handleChange={setLeft} />
        <AreaGrid
          className='area-grid'
          area_selected={areaSelected}
          onClick={handleChangeArea} />
        <NeighborSelect name='東' pos='right' value={right} handleChange={setRight} />
      </div>
      <div className='bottom'>
        <NeighborSelect name='南' pos='down' value={down} handleChange={setDown} />
      </div>
    </>
  )
}

export default AreaInput