import { Link, useNavigate } from "react-router-dom";
import routes from '../constants/routes.js';
import "../css/Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(routes.top);  // クリックしたときに遷移するパスを指定
  };

  const handleLogoKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(routes.top);  // Enter または Space キーが押されたときに遷移するパスを指定
    }
  };

  return (
    <footer>
      <div className="container-footer">
        <img
          className="footer-logo-footer"
          src="/images/logo-beta.png"
          alt="logo at footer"
          onClick={handleLogoClick}
          onKeyDown={handleLogoKeyDown}
          tabIndex="0"
        />
        <div className="contents-footer">
          <Link to={routes.top}><p className="content-footer">間取りの自動生成</p></Link>
          <Link to={routes.contact}><p className="content-footer">お問い合わせ</p></Link>
          <Link to={routes.top}><p className="content-footer">広告掲載について</p></Link>
          <Link to={routes.privacy}><p className="content-footer">プライバシーポリシー</p></Link>
        </div>
      </div>
      <p className="copyright-footer">© 2024 間取りの窓口</p>
    </footer>
  );
}

export default Footer;