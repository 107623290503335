import '../css/NLDKSelection.css'
import SelectionTitleLabel from './SelectionTitleLabel'
import { useStep2 } from '../contexts/Step2Context.jsx'

const NLDKSelection = () => {

  const { nLDK, setNLDK } = useStep2()

  const NLDK_SELECT_OPTIONS = [1, 2, 3, 4]

  const handleKeyDown = (e, value) => {
    if ((e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault()
      setNLDK(value)
    }
  }

  return (
    <div className='container-nldk-selection'>
      <SelectionTitleLabel title='nLDK' />
      <div className='button-container-nldk-selection'>
      { NLDK_SELECT_OPTIONS.map((nldk) => (
        <div key={nldk}>
          <input 
            type='radio'
            name='nldk-select' 
            className={Number(nLDK)===nldk ? 'checked-nldk-selection' : undefined}
            value={nldk} 
            id={`nldk-select-${nldk}`}
            checked={Number(nLDK)===nldk}
            onChange={nldk<4 ? (e) => setNLDK(e.target.value) : undefined}
            disabled={nldk>3}
          />
          <label 
            htmlFor={`nldk-select-${nldk}`}
            className={nldk<4 ? 'input-label-nldk-selection' : 'disable-input-label-nldk-selection'}
            onClick={nldk<4 ? (e) => setNLDK(e.target.value) : undefined}
            data-value={nldk}
            disabled={nldk>3}
            tabIndex={nldk<4 ? "0" : "-1"}
            onKeyDown={(e) => handleKeyDown(e, nldk)}
          >
            <div 
              className='button-text-nldk-selection' 
              data-value={nldk}
            >
              {nldk}LDK
            </div>
          </label>
        </div>
      )) }
      </div>
    </div>
  )
}

export default NLDKSelection