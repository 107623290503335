import { BrowserRouter, Route, Routes } from "react-router-dom";
import './css/Reset.css';
import './App.css';
import ScrollToTop from './components/ScrollToTop.jsx'
import RoomSizeSelector from './components/RoomSizeSelector.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import Contact from './components/Contact.jsx';
import NotFound from './components/NotFound.jsx';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className='App'>
        <Header />
        <div className='container-main'>
          <Routes>
            <Route path="/" element={<RoomSizeSelector />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
