import React from 'react';

function Cell(props) {
  let className = 'non-selected-cell'
  if (props.cell === 1) {
    className = 'selected-cell'
  }

  return (
    <button
      className={'area-cell ' + className}
      onClick={() => {
        props.onClick(props.i, props.j)}
      }></button>
  );
}

const AreaGrid = (props) => {
  const area_selected = props.area_selected
  return (
    <div className='area-grid'>
      {area_selected.map((row, rowIndex) => (
        <div className='area-row' key={rowIndex}>
          {row.map((cell, cellIndex) => (
            <Cell i={rowIndex} j={cellIndex} cell={cell} key={cellIndex} onClick={props.onClick}></Cell>
          ))}
        </div>
      ))}
      <br></br>
    </div>
  );
};

export default AreaGrid;



